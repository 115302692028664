<template>
  <div>
    <BaseTableWrapper v-if="!loading" :table-init="tableInit">
      <h1 class="page-header">Customer Accounts</h1>
    </BaseTableWrapper>
    <ImportModal
      v-if="showImportDialog"
      id="customer-accounts-list"
      :show="showImportDialog"
      entity-type="customerAccount"
      @close="importCancelled"
      @complete="importComplete"
    >
      <template slot="header">
        <h2>Customer Account Import</h2>
      </template>
      <template slot="downloadTemplate">
        <div>
          <strong>Need help? Download our CSV template.</strong>
        </div>
        <v-btn
          id="customer-accounts-list-button-download-template"
          class="download-template-button btn-secondaryaction"
          outline
          @click="downloadTemplate"
        >
          Download Template
        </v-btn>
        <br />
        <br />
      </template>
      <template slot="note">
        <strong>
          Note: Industries must already be added or imported for customers
          accounts to link.
        </strong>
        <br />
        <br />
      </template>
    </ImportModal>
  </div>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import ImportModal from '@/components/ImportModal.vue'
import customerAccounts from '@/services/customerAccounts'
import { phoneFormatFilter } from '@/utils/phone'
import { authComputed } from '@/state/helpers'
import { saveAs } from 'file-saver'
import { mapActions, mapGetters } from 'vuex'
import customerAccountsActionsWrapper from '../components/CustomerAccountsActionsWrapper'
import CustomerAccountsTableActions from '@/components/CustomerAccountsTableActions.vue'
import { SplitFeatureFlag } from '@/utils/enum'

export default {
  components: {
    BaseTableWrapper,
    ImportModal,
  },
  data() {
    return {
      filters: () => [],
      sorts: () => [],
      isAdmin: false,
      loading: true,
      showImportDialog: false,
      isTiersEnabled: false,
      tableInit: {
        addNewEnabled: true,
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        enableImport: true,
        enableColumnConfig: true,
        externalShareFilters: this.receiveFilters,
        externalShareSorts: this.receiveSorts,
        importTable: this.importClicked.bind(this),
        exportServiceAction: (params) =>
          customerAccounts.exportCustomerAccounts(params),
        exportName: 'customer_accounts.csv',
        addNewHandler: this.addNewHandler,
        detailKeyId: 'customerAccountId',
        tableId: 'customer_accounts_tv_view',
        currentPage: 1,
        perPage: 10,
        forceRefresh: 0,
        isDetailed: false,
        columns: [],
        collection: 'CustomerAccounts',
        action: (params) => customerAccounts.getCustomerAccounts(params),
      },
      columns: [],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      tiersTVMap: 'tiers/getTiersTVMap',
      predefinedTiersTVMap: 'tiers/getPredefinedTiersTVMap',
    }),
  },
  async mounted() {
    this.tableInit.enableExport = this.canExport()
    this.loading = false

    this.isTiersEnabled = await this.isFeatureEnabled(SplitFeatureFlag.ServiceTier)
    if (this.isTiersEnabled) {
      await this.fetchAllTiers()
      this.initializeTiersTVSupport()
    }

    await this.getColumns()
    this.tableInit.columns.push(...this.columns)
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
      fetchAllTiers: 'tiers/fetchAllTiers',
      initializeTiersTVSupport: 'tiers/initializeTiersTVSupport'
    }),
    phoneFormatFilter,
    async addNewHandler() {
      const component = () => import('@/components/CustomerAccountSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Add Customer Account',
          mode: 'add',
        },
        component,
      })
    },
    async getColumns() {
      const customerAccountsWrappedActions = await customerAccountsActionsWrapper(
        CustomerAccountsTableActions
      )
      this.columns.push(
        {
          _t_id: 'x614fbb01',
          prop: '/',
          text: 'Actions',
          component: customerAccountsWrappedActions,
          sort: false,
          filter: false,
          detail: false,
          type: 'actions',
        },
        {
          _t_id: '51cf03e2',
          prop: 'customerAccountId',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'number',
          filterType: 'eq',
          defaultSort: true,
          detail: false,
        },
        {
          _t_id: '51cf0662',
          prop: 'name',
          text: 'Account Name',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          filterAsIs: true, // This param is used to avoid the text being split by spaces, and 'OR'd
          sortProp: 'name',
          detail: false,
        },
        {
          _t_id: '51cf07ca',
          prop: 'email',
          text: 'Email',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'email',
          detail: false,
        },
        {
          _t_id: '51cf090a',
          prop: 'phone',
          text: 'Phone',
          sort: true,
          filter: true,
          type: 'text',
          computedText: (item) => phoneFormatFilter(item),
          filterType: 'contains',
          sortProp: 'phone',
          detail: false,
        },
        {
          _t_id: '51cf0a4a',
          prop: 'industry/label',
          text: 'Industry',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'industry/label',
          detail: false,
        },
        {
          _t_id: '51cf0d6a',
          prop: ['address/street1', 'address/street2'],
          text: 'Address',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'address/street1',
          detail: false,
        },
        {
          _t_id: '51cf0eb4',
          prop: ['address/city', 'address/state'],
          text: 'City and State',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'address/city',
          detail: false,
        },
        {
          _t_id: '51cf0fe0',
          prop: 'company/name',
          text: 'Associated Company',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'company/name',
          detail: false,
        },
        {
          _t_id: 'bd4c7350-be94-48c2-b318-23e0b01a6f92',
          prop: 'industries',
          text: 'Industries',
          computedText: (item, row) =>
            item ? item.replaceAll('||', ' / ') : '',
          sort: true,
          sortProp: 'industries',
          filterProp: 'industries',
          filter: true,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
      )

      if (this.isTiersEnabled) {
        this.columns.push(
          {
            _t_id: 'e65f64bc-0a56-4532-b46b-219535cdd122',
            prop: 'tierId',
            text: 'Tier',
            computedText: (item) => this.mapCategoryText(this.tiersTVMap, item),
            sort: true,
            filter: true,
            detail: false,
            filterType: 'eq',
            defaultHidden: false,
            predefined: this.predefinedTiersTVMap,
          }
        )
      }
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    importCancelled() {
      this.showImportDialog = false
    },
    importClicked() {
      this.showImportDialog = false
      this.$nextTick(() => {
        this.showImportDialog = true
      })
    },
    importComplete() {
      this.showImportDialog = false
      this.tableInit.forceRefresh = 1 + Math.random()
    },
    downloadTemplate() {
      let csv = `name,email,phone,industryId
Joan Deer,example@example.com,7770001111,100
`
      csv = new Blob(['\ufeff', csv])
      saveAs(csv, 'customerAccountsTemplate.csv')
    },
    canExport() {
      const roles = this.currentUserProfile?.roles || []
      const canExportRole = roles.find(
        (r) =>
          !!r.permissions.find(
            (p) => p.permissionName === 'canViewCompanyMetrics'
          )
      )
      return !!canExportRole
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
  },
}
</script>
<style lang="scss" scoped>
.download-template-button {
  margin-left: 0px;
}
</style>
